<template>
  <div>


    <div v-if="!selectedSpecialty">
      <h2 class="mb-3">{{  $t('ChooseSpecialty')  }}</h2>
      <ws-data-table

          :items="items"
          :headers="headers"
          :row-action="($event) => selectedSpecialty = $event"
      >
        <template #item.name="{item}">
          <h5> {{ item.name }}</h5>
        </template>
        <template #item.degrees="{item , index}">
          <h5 v-for="degree in item.degrees" :key="index + degree.uuid">{{ degree.name }}</h5>
        </template>

      </ws-data-table>
    </div>

    <div v-else >
      <h2 @click="selectedSpecialty = null" class="mb-3 pointer" :style="`color : ${wsACCENT}`">
        <v-icon :color="wsACCENT" >mdi-chevron-left</v-icon>
        {{  $t('Back')  }}
      </h2>
      <h1> {{ selectedSpecialty.name }}</h1>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "studentSpecialtySelect",
  data() {
    return {
      items : [],
      selectedSpecialty : null
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Degree') , value : 'degrees' },
      ]
    }
  },
  methods : {
    ...mapActions('wsu_application' , ['GET_APPLICATION_SPECIALTIES']),

    async initPage() {

      let result = await this.GET_APPLICATION_SPECIALTIES()
      if ( !result ) {
        return
      }
      this.items = result.specialties
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>