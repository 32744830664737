<template>
  <dash-page-new
      :title="$t('Application')"
      :subtitle="$t('Education')"
      :filters="[{text : 'sd'}]"
      :filters-input="{}"
  >
    <template #default>
      <ws-navigation
          :items="navigationSelect"
      >
        <template #item.application>
          <student-specialty-select />
        </template>
        <template #item.documents>
          <student-upload-documents />
        </template>
      </ws-navigation>
    </template>
  </dash-page-new>
</template>

<script>
import studentSpecialtySelect from "@/components/university/student/application/studentSpecialtySelect";
import studentUploadDocuments from "@/components/university/student/application/studentUploadDocuments";
export default {
  name: "StudentApplication",
  components: {
    studentSpecialtySelect,
    studentUploadDocuments
  },
  computed : {
    navigationSelect() {
      return [
        { text : this.$t('Application') , value : 'application' },
        { text : this.$t('EducationDocuments') , value : 'documents' },
      ]
    }
  }
}
</script>

<style scoped>

</style>