<template>
  <div>
    <h3>Upload documents: </h3>
    <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`" class="wsRoundedLight pa-5">
      <h3> School</h3>
      <v-divider class="mt-2" />
      <div class="d-flex justify-space-between align-center mt-3">
        <div class="d-flex align-center mr-3 mt-1">

          <ws-date-picker
              custom-activator

          >
            <template #default="{time}">
              <h5 class="text-no-wrap mr-2" :style="!time ? `color : ${wsDARKLIGHT}` : null">
                {{ time || $t('EducationStart') }}
                <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
              </h5>
            </template>
          </ws-date-picker>

          <ws-date-picker
              custom-activator
          >
            <template #default="{time}">
              <h5 class="text-no-wrap mr-2" :style="!time ? `color : ${wsDARKLIGHT}` : null">
                {{ time || $t('EducationEnd') }}
                <v-icon :color="wsACCENT">mdi-menu-down</v-icon>
              </h5>
            </template>
          </ws-date-picker>

        </div>
        <ws-text-field area width="100%" solo flat row-height="1" :placeholder="$t('EnterEducationEstablishmentName')"
        />
        <h5>High School</h5>
      </div>

      <v-divider class="my-2" />

      <ws-data-table
          no-header
          :headers="headers"
          :items="educationDocumentsTypes"
          :row-action="($event) => { openUploadDocuments($event.uuid)}"
      >

        <template #item.name="{item}">
          <h5>{{ item.name }}</h5>
        </template>

        <template #item.files="{}">
          <div class="my-3">
            <h5 class="font-weight-regular text-no-wrap">
              <v-icon small>mdi-file-outline</v-icon>
              document_scan.pdf
            </h5>
            <h5 class="font-weight-regular text-no-wrap">
              <v-icon small>mdi-file-outline</v-icon>
              document_scan.pdf
            </h5>
            <h5 class="font-weight-regular mt-3"><v-icon small>mdi-plus</v-icon>3 more files</h5>
          </div>

        </template>
      </ws-data-table>



    </v-sheet>

    <div class="d-flex align-center justify-center fill-height">

      <div>
        <template v-if="items.length === 0">
          <div class="d-flex justify-center">
            <v-icon size="80" :color="wsACCENT">mdi-school-outline</v-icon>
          </div>
          <h3 class="text-center mt-3" style="max-width: 450px">{{ $t('wsu.application.documents.no_items.title') }}</h3>
          <h4 class="text-center font-weight-regular" style="max-width: 550px">{{ $t('wsu.application.documents.no_items.description') }}</h4>

        </template>

        <v-sheet width="340" class="wsRoundedLight d-flex mx-auto align-center mt-6" :style="`border : 1px solid ${wsACCENT}`">
          <template v-for="(type,index) in educationDegreeTypesMain" >

            <template v-if="index < 3">
              <v-btn @click="addNewDocument(type.value)" text class="noCaps" :color="wsACCENT" :key="index + 'type'">
                <h5>{{ type.text}}</h5>
              </v-btn>
              <v-divider  v-if="index !== educationDegreeTypes.length - 1" :key="index + 'divider' + i"
                          vertical :style="`border-color : ${wsACCENT}`"  />
            </template>
          </template>
          <ft-select
              min-width="100"
              @input="addNewDocument($event , topic.uuid , i)"
              :items="educationDegreeTypesMore">
            <v-btn text class="noCaps pt-1 pr-n6" :color="wsACCENT" :key="'more_types'">
              <h5 class="mr-n1">{{ $t('More')}} <v-icon :color="wsACCENT">mdi-menu-down</v-icon></h5>
            </v-btn>
          </ft-select>
        </v-sheet>



      </div>
    </div>


    <ws-dialog
        :title="$t('UploadSchoolDocument')"
        v-model="displayUploadDialog"
    >
      <h1> {{ selectedDocumentType  }}</h1>
      <h3>openUploadDocuments</h3>


    </ws-dialog>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "studentUploadDocuments",
  data() {
    return {
      result : {},
      documentTypes : [],
      items : [
        {
          date_start : '',
          date_end : '',
          name : '',
          specialty : ''
        }
      ],
      displayUploadDialog : false,
      selectedDocumentType : null
    }
  },
  computed : {
    headers() {
      return [
        { value : 'name' },
        { value : 'files' , width : 50},
      ]
    },
    educationDocumentsTypes() {
      return this.documentTypes.filter(el => el.is_enrollment_education)
    },
    educationDegreeTypesMain() {
      return this.educationDegreeTypes.filter((_,index) => index  < 4)
    },
    educationDegreeTypesMore() {
      return this.educationDegreeTypes.filter((_,index) => index  > 3)
    },

    educationDegreeTypes() {
      return [
        { text : this.$t('School') , value : 'school' },
        { text : this.$t('College') , value : 'college' },
        { text : this.$t('Bachelor') , value : 'bsc' },
        { text : this.$t('Master') , value : 'msc' },
        { text : this.$t('Phd') , value : 'phd' }
      ]
    }
  },
  methods : {
    ...mapActions('wsu_application' , [
      'GET_STUDENT_APPLICATION_DOCUMENTS',
    ]),

    openUploadDocuments(type) {
      this.selectedDocumentType = type
      this.displayUploadDialog = true
    },
    addNewDocument(type) {
      this.notify('Add new document: ' + type)
    },
    async initPage() {
      let result = await this.GET_STUDENT_APPLICATION_DOCUMENTS()
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.documentTypes = result.document_types
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>